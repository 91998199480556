import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0


  return (
    <Layout title={siteTitle}>
      <Seo
        title="Georg Gilstein - Cinematographer"
        description="Freelance DoP and Cinematographer."
        keywords={[`georg gilstein`, `dop`, `cinematographer`, 'filmemacher', 'kameramann', 'darmstadt', `frankfurt`, `cameraman`]}
        lang="en"
      />
      <h1 className="no-display">Georg Gilstein - Director of Photography</h1>
      <h4 className="subheading">Freelance Cinematographer<br/>
      <a href="/about">about me</a></h4>
      <p className="no-display">
          I'm Georg Gilstein, a DoP and Cinematographer with focus on commercial and artistical films.<br /><br />
          I both work in teams and together with production companies and agencies, but also with direct clients and offer full-service video production.<br /><br />
          My goal is to use my skills and ressources to create informational and emotional content which moves people and make people think.<br /><br />
          I'm always open for new projects.
      </p>
      <hr/>
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            
              <div className="post-card">
              <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
              videoThumbnail={node.frontmatter.videoThumbnail}
            />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
            videoThumbnail {
              relativePath
            }
          }
        }
      }
    }
  }
`

const index = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
);
export default index;
