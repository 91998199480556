import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

  // handle mouse enter
  const handleMouseEnter = (e) => {
    const vid = e.target
    vid.classList.remove("post-card-video-off");
    vid.play()
  }
  // handle mouse leave
  const handleMouseLeave = (e) => {
    const vid = e.target
    vid.classList.add("post-card-video-off");
    vid.currentTime = 0
    vid.pause()
    
  }

const postcard = props => (
  <article
    className={`post-card-2 ${props.postClass} ${props.node.frontmatter.thumbnail ? `with-image` : `no-image`}`}
    
  >
    <Link to={props.node.fields.slug} className="post-card-link post-card-content">
        <h2 className="post-card-title">{props.node.frontmatter.title}</h2>
        {props.node.frontmatter.videoThumbnail &&
        <video className="post-card-thumbnail post-card-video-off" loop muted playsInline onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} onTouchStart={handleMouseEnter} onTouchEnd={handleMouseLeave}>
          <source
            src={props.node.frontmatter.videoThumbnail.relativePath}
            type="video/mp4"
          />
        </video>}
      {props.node.frontmatter.thumbnail && 
      <GatsbyImage
            className="post-card-thumbnail"
            image={props.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
            alt={props.node.frontmatter.title}
            objectFit="cover"
            />}
       
    </Link>
  </article>
)
export default postcard;